<script setup lang="ts">
// [ stores ]
import { useAppStore } from '@/stores/app-store';
// [ store - lang ]
import { useLangStore } from '@/stores/lang-store';
import { ja } from '@/locales/home/lang-ja';
import { en } from '@/locales/home/lang-en';
// ---------------------------------------
// [ router ]
const router = useRouter();
// [ stores ]
const appStore = useAppStore();
// [ store - lang ]
const langStore = useLangStore();
langStore.setMessage(en, ja);
const t = langStore.t;
// [ Nuxt ]
definePageMeta({
  middleware: 'redirect',
  layout: 'custom-no-header',
});
useHead({
  title: 'Hare-Systems > Home',
});
const fixed = computed(() => {
  return {
    height: appStore.windowFixedH,
  };
});
// ---------------------------------------
</script>
<template>
  <section class="main f-rubik" :style="fixed">
    <div class="title-group" @click="router.push({ path: `/${langStore.state.lang}/about-us` })">
      <div class="title-left-box"></div>
      <div class="menu slide">Application Development</div>
      <div class="menu slide">Project Management</div>
      <div class="menu slide">Process Optimization</div>
      <div class="menu slide">User eXperience Optimization</div>
      <div class="logo slide">
        <img src="/assets/img/logo-long-rev-no-bg.svg" alt="Logo" />
      </div>
    </div>
    <div class="btn-about">
      <v-btn
        variant="flat"
        color="white"
        size="x-large"
        class=""
        @click="router.push({ path: `/${langStore.state.lang}/about-us` })"
      >
        {{ t('page.goAbout') }}
      </v-btn>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
.main {
  background-color: #ff9100;
  height: 100vh;
  min-height: 300px;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-height  : #{ 0 }px) and (max-height: #{ 400 - 0.1}px) {
    align-items: stretch;
    padding: 10px 0;
  }

  .title-group {
    color: white;
    overflow: hidden;
    padding: 0 0 0 1.2em;
    cursor: pointer;
    transition: all 300ms;
    &:hover {
      transform: scale(1.02);
    }

    .title-left-box {
      position: absolute;
      inset: 0 auto 0 0;
      background-color: white;
      width: 0.5em;
    }

    //レスポンシブ項目
    width: 300px;
    font-size: 16px;

    .menu {
      font-size: 1em;
      line-height: 1em;
      &:not(:last-child) {
        margin-bottom: 0.5em;
      }
      transition: all 300ms;
    }
    .logo {
      padding: 0.2em 0 0px 0px;
    }
    @media screen and (min-width: #{ 400 }px) and (max-width: #{ 600 - 0.1}px) {
      width: 380px;
      font-size: 18px;
    }
    @media screen and (min-width: #{ 600 }px) and (max-width: #{ 800 - 0.1}px) {
      width: 580px;
      font-size: 20px;
    }
    @media screen and (min-width: #{ 800 }px) {
      width: 780px;
      font-size: 22px;
      .menu {
        &:not(:last-child) {
          margin-bottom: 0.6em;
        }
      }
      .logo {
        padding: 0.5em 0 0px 0px;
      }
    }
  }
}

.btn-about {
  position: absolute;
  inset: auto 16px 16px auto;
  background-color: transparent;
  color: white;
  font-size: 22px;
}
</style>
